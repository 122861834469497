/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-danger-with-children */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Fade } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { CircularProgressbar } from 'react-circular-progressbar';
import { NotebookText } from 'lucide-react';
import Spinner from './Loading';
// js
import {
    MainHeader,
    ButtonClose,
    ContainerOptionsUpload,
    ContainerTest,
    Body,
    Header,
    ContainerArchive,
    ModalContainer,
    ContainerHome,
    Footer,
    ButtonExit,
    Container,
    ContainerHeader,
    IconBlue,
    TextNewCollaborator,
    DivLine,
    TextInput,
    InputText,
    ButtonSave,
    DivInputText,
    ContainerNewCollaborator,
    ContainerManage,
    ContainerSelect,
    ContainerOption,
    InputNullSelectedArchive,
    TextArchive,
    ContainerMainCategories,
    IconArrowCategoriesGoBack,
    TextFinish,
    ContainerCategoriesSelected,
    TxtCategoriesSelected,
    ContainerRadius,
    ContainerCicleChecked,
    IconArrowCategories,
    IconChecked,
    ContainerDropzone,
    TextDropzone,
    ContainerProgressBar,
    TextProgressBar,
    IconDownload,
    ContainerAdjustSelects,
} from './ModalManageStyles';
import SelectConstants from './SelectConstants';
import filtersRequest from '../../../../services/filters';
import questions from '../../../../services/questions';
import uploads from '../../../../services/uploads';
import constants from '../../../../configs/constants';

// images
import iconAddQuestions from '../../../../assets/images/iconAddQuestions.png';
import checkCircleBlueSea from '../../../../assets/images/checkCircleBlueSea.png';
import arrowTip from '../../../../assets/images/arrowTip.png';
import iconDownloadBlue from '../../../../assets/images/iconDownloadBlue.png';

// contexts
import { AuthContext } from '../../../../context/AuthContext';

// styles
import 'react-circular-progressbar/dist/styles.css';
import book from '../../../../services/book';
import functions from '../../../../utils/functions';
import subjects from '../../../../services/subjects';

// consts
const baseURL = constants.baseUrl;
const GetToken = localStorage.getItem('@Engequest:token') || '';
const configs = {
    headers: {
        'Content-Type': 'application/json',
        'x-access-token': GetToken || '',
    },
};

// interfaces
interface DataFileModuleInterface {
    file: any,
    eventFile: any,
    eventName: string,
}

interface AmountInterface {
    name: string,
    id: number,
}

const modalManagePage = (
    open: boolean,
    handleClose: any,
    userEdit: any,
    allCategories: any,
    allBooks: any,
) => {
    // context
    const { user } = useContext(AuthContext);

    // use dropzone
    const [messageDropzone, setMessageDropzone] = useState('Clique para selecionar arquivo [questions.zip]');
    const [progressId, setProgressId] = useState(0);
    const [progressUpload, setProgressUpload] = useState(0);
    const [progressBulk, setProgressBulk] = useState(0);
    const [messageBulk, setMessageBulk] = useState('');
    const [messageBulkItems, setMessageBulkItems] = useState([]);
    const [fileDropzone, setFileDropzone] = useState<any>(null);
    const configUseDropzone = {
        accept: 'application/x-rar-compressed,application/octet-stream,application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
        onDrop: async (acceptedFiles: any) => {
            if (acceptedFiles.length === 1) {
                const newFileDropzone = acceptedFiles.map((file: any) => Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }));
                if (newFileDropzone[0].path !== 'questions.zip') {
                    setMessageDropzone('Arquivo [' + newFileDropzone[0].name + '] não é [questions.zip]');
                } else {
                    setFileDropzone(newFileDropzone[0]);
                }
            } else {
                setMessageDropzone('Formato não suportado');
            }
        },
    };
    const { getRootProps, getInputProps } = useDropzone(configUseDropzone);

    // constants
    let intervalClear: any = null;
    const adjustButtonArchive = { width: '100%' };
    const initialFile: DataFileModuleInterface = {
        eventFile: new Blob(),
        eventName: 'Importar .DOCX',
        file: null,
    };
    const initialFileMovie: DataFileModuleInterface = {
        eventFile: new Blob(),
        eventName: 'Importar Vídeo',
        file: null,
    };

    // states
    const [currentStatement, setCurrentStatement] = useState('');
    const [currentAnswer, setCurrentAnswer] = useState('');

    // Informação da questão em base64 para conversão para .docx
    const [currentStatementBase64, setCurrentStatementBase64] = useState('');
    const [currentAnswerBase64, setCurrentAnswerBase64] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [statementUploadName, setStatementUploadName] = useState('Nenhum arquivo .docx selecionado.');
    const [answerUploadName, setAnswerUploadName] = useState('Nenhum arquivo .docx selecionado.');
    const [statementIsSelected, setStatementIsSelected] = useState(false);
    const [answerIsSelected, setAnswerIsSelected] = useState(false);

    const [selectBook, setSelectBook] = useState(null);
    const [selectExamColor, setSelectExamColor] = useState(null);
    const [selectReference, setSelectReference] = useState(null);
    const [pageBook, setPageBook] = useState(null);
    const [numberQuestion, setNumberQuestion] = useState(null);
    const [archiveStatement, setArchiveStatement] = useState<any>(initialFile);
    const [archiveAnswer, setArchiveAnswer] = useState<any>(initialFile);
    const [archiveMovie, setArchiveMovie] = useState(initialFileMovie);
    const [typeQuestion, setTypeQuestion] = useState('discursive');
    // Significa quantas alternativas existem em number
    const [selectAmountOption, setSelectAmountOption] = useState('');
    // Significa qual alternativa de A-H é a correta
    const [selectCorrectOption, setSelectCorrectOption] = useState('');
    const [selectCorrectRightOrWrong, setSelectCorrectRightOrWrong] = useState('0');
    // Significa quantas alternativas existem de A-H
    const [selectCorrectAllOption, setSelectCorrectAllOption] = useState<Array<string> | string>('');
    const [year, setYear] = useState('');

    const [amountSelectedFalse, setAmountSelectedFalse] = useState<AmountInterface[]>([]);
    const [subjectSelectedFalse, setSubjectSelectedFalse] = useState<AmountInterface[]>([]);
    const [amountSelected, setAmountSelected] = useState<AmountInterface[]>([]);
    const [subjectSelected, setSubjectSelected] = useState<AmountInterface[]>([]);
    const [indexCategories, setIndexCategories] = useState(1);
    const [idCategories, setIdCategories] = useState(0);
    const [allFilters, setAllFilters] = useState([]);
    const [allGeneralFilters, setAllGeneralFilters] = useState<any>([]);
    const [allGeneralSubjects, setAllGeneralSubjects] = useState([]);
    const [allGeneralBooks, setAllGeneralBooks] = useState([]);
    const [filtersSelected, setFiltersSelected] = useState<any>({});
    const [searchFilters, setSearchFilters] = useState('');
    const [subjectsSelected, setSubjectsSelected] = useState<any>({});
    const [searchSubjects, setSearchSubjects] = useState('');
    const [booksSelected, setBooksSelected] = useState<any>({});
    const [searchBooks, setSearchBooks] = useState('');

    // apis
    async function BulkUpload(idProgress = 0, progress = -1, message = '') {
        // BulkProgress precisa saber qual progress está dando track
        // Novo Parâmetro necessário
        let url = `${baseURL}/questions/bulkProgress?idProgress=${idProgress}`;
        if (progress >= 0) {
            url += `?progress=${progress}&message=${message}&idManager=${user.id}`;
        }
        const response = await axios.get(url, configs);
        if (response.data?.result) {
            setMessageBulk(response.data.result?.message || '');
            setMessageBulkItems(response.data.result?.error_items || []);
            setProgressBulk(response.data.result?.progress || 0);
            if (response.data.result?.progress === 100) {
                clearInterval(intervalClear);
            }
        }
    }

    function getProgressBulk(idProgress: number) {
        intervalClear = setInterval(async () => {
            BulkUpload(idProgress);
        }, 2000);
    }

    const GetFilters = async () => {
        const GetAllFilters = await filtersRequest.GetSelectedFiltersSearch(searchFilters, localStorage.getItem('@Engequest:token') || '');
        setAllGeneralFilters(GetAllFilters?.result || []);
    };

    const GetSubjects = async () => {
        if (filtersSelected?.id) {
            const GetAllSubjects = await subjects.GetAllSubjectsSearch(searchSubjects, filtersSelected.id, localStorage.getItem('@Engequest:token') || '');
            setAllGeneralSubjects(GetAllSubjects?.result || []);
        }
    };

    const GetBooks = async () => {
        const GetAllBooks = await book.GetAllBookSearch(searchBooks, localStorage.getItem('@Engequest:token') || '');
        setAllGeneralBooks(GetAllBooks?.result || []);
    };

    // useEffect
    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetFilters();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchFilters]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetSubjects();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchSubjects]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            GetBooks();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchBooks]);

    useEffect(() => {
        if (userEdit) {
            setSelectBook(userEdit?.id_book_reference);
            setSelectExamColor(userEdit?.exam_color);
            setSelectReference(userEdit?.type_reference);
            setPageBook(userEdit?.page);
            setNumberQuestion(userEdit?.id);
            setCurrentStatement(userEdit?.statement);
            setCurrentAnswer(userEdit?.answer);
            setArchiveStatement(userEdit?.statement || initialFile);
            setArchiveAnswer(userEdit?.answer || initialFile);
            setArchiveMovie(userEdit?.video || initialFileMovie);
            if (userEdit?.options?.length) {
                setSelectAmountOption(`${userEdit?.options?.length}${userEdit?.options}`);
            }
            setSelectCorrectOption(userEdit?.feedback || '');
            setSelectCorrectAllOption(userEdit?.options || '');
            setYear(userEdit?.exam_year || '');
            setAmountSelected(userEdit?.id_filters || []);
            setSubjectSelected(userEdit?.id_subjects || []);
            setTypeQuestion(userEdit?.type);
            setSelectCorrectRightOrWrong(JSON.stringify(userEdit?.option_right_or_wrong));
            setStatementUploadName('Nenhum arquivo .docx selecionado.');
            setAnswerUploadName('Nenhum arquivo .docx selecionado.');
        } else {
            setSelectBook(null);
            setSelectExamColor(null);
            setSelectReference(null);
            setPageBook(null);
            setNumberQuestion(null);
            setArchiveStatement(initialFile);
            setArchiveAnswer(initialFile);
            setArchiveMovie(initialFileMovie);
            setSelectAmountOption('');
            setSelectCorrectOption('');
            setSelectCorrectAllOption('');
            setYear('');
            setAmountSelected([]);
            setSubjectSelected([]);
            setTypeQuestion('discursive');
            setSelectCorrectRightOrWrong('0');
            setStatementUploadName('Nenhum arquivo .docx selecionado.');
            setAnswerUploadName('Nenhum arquivo .docx selecionado.');
        }
    }, [userEdit]);

    // methods
    function RenderNumberSelected(idCategory: any) {
        const getOnlyIds = amountSelected?.map((v: any) => v.id);
        const [getOnlyAllFilters] = allCategories?.filter((v: any) => v.id === idCategory);
        const filtersOnlyIds = getOnlyAllFilters.allFilters.filter((v: any) => getOnlyIds?.includes(v));
        return filtersOnlyIds.length;
    }

    const handleClick = (v: any, valueSubject?: any) => {
        if (amountSelectedFalse?.filter((item) => item.id === v.id).length) {
            const removeItem = amountSelectedFalse?.filter((item) => item.id !== v.id);
            setAmountSelectedFalse(removeItem);
            if (valueSubject) {
                const allIdsSubject = valueSubject.map((item: any) => item.id);
                const removeItemSubject = subjectSelectedFalse.filter((item) => !allIdsSubject.includes(item.id));
                setSubjectSelectedFalse(removeItemSubject);
            }
        } else {
            setAmountSelectedFalse([...amountSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    const handleClickSubject = (v: any, valueFilter: any) => {
        if (subjectSelectedFalse.filter((item) => item.id === v.id).length) {
            const removeItem = subjectSelectedFalse.filter((item) => item.id !== v.id);
            setSubjectSelectedFalse(removeItem);
            if (!removeItem.length) {
                handleClick(valueFilter);
            }
        } else {
            setSubjectSelectedFalse([...subjectSelectedFalse || [], { name: v.name, id: v.id }]);
        }
    };

    function Select(title: string, value: any) {
        function RenderOptionsQuestion() {
            if (title === 'Selecione uma opção') {
                return SelectConstants.map((item) => (
                    <ContainerOption value={`${item.value}${item.options}`}>{item.value}</ContainerOption>
                ));
            }
            if (selectCorrectAllOption && title !== 'Selecione uma opção' && title !== 'Selecione um livro' && title !== 'Selecione uma referência' && title !== 'Selecione uma cor' && title !== 'Tipo da questão' && title !== 'Tipo das questões' && title !== 'Certa ou errada') {
                if (typeof selectCorrectAllOption === 'string') {
                    return selectCorrectAllOption.split(',').map((item) => (
                        <ContainerOption>{item}</ContainerOption>
                    ));
                }
                return selectCorrectAllOption.map((item) => (
                    <ContainerOption>{item}</ContainerOption>
                ));
            }
            if (title === 'Selecione um livro') {
                return allBooks.map((item: any) => (
                    <ContainerOption value={item.id}>{item.name}</ContainerOption>
                ));
            }
            if (title === 'Selecione uma referência') {
                const allReferencies = [
                    {
                        name: 'Autor Desconhecido',
                        id: 'unknown_author',
                    },
                    {
                        name: 'Elaboração Própria',
                        id: 'self_elaboration',
                    },
                    {
                        name: 'Concurso',
                        id: 'contest',
                    },
                    {
                        name: 'Livro',
                        id: 'book',
                    },
                ];
                return allReferencies.map((item: any) => (
                    <ContainerOption value={item.id}>{item.name}</ContainerOption>
                ));
            }
            if (title === 'Selecione uma cor') {
                const allColors = ['AMARELO', 'VERDE'];
                return allColors.map((item: any) => (
                    <ContainerOption value={item}>{item}</ContainerOption>
                ));
            }
            if (title === 'Tipo da questão' || title === 'Tipo das questões') {
                const allTypes = ['DISCURSIVA,discursive', 'MÚLTIPLA ESCOLHA,multiple_choices', 'CERTA OU ERRADA,right_or_wrong'];
                return allTypes.map((item: any) => {
                    const name = item.split(',')[0];
                    const identifier = item.split(',')[1];
                    return (
                        <ContainerOption value={identifier}>{name}</ContainerOption>
                    );
                });
            }
            if (title === 'Certa ou errada') {
                const allOptions = ['ERRADA,0', 'CERTA,1'];
                return allOptions.map((item: any) => {
                    const name = item.split(',')[0];
                    const identifier = item.split(',')[1];
                    return (
                        <ContainerOption value={identifier}>{name}</ContainerOption>
                    );
                });
            }

            return null;
        }

        function ChangeSelect(txt: any) {
            const valueSelected = txt.target.value;

            if (title === 'Selecione uma opção' && valueSelected !== 'Selecione uma opção') {
                setSelectAmountOption(valueSelected);
                setSelectCorrectAllOption(valueSelected.slice(1));
            } else if (title === 'Selecione uma alternativa' && valueSelected !== 'Selecione uma alternativa') {
                setSelectCorrectOption(valueSelected);
            } else if (valueSelected === 'Selecione uma opção') {
                setSelectAmountOption('');
                setSelectCorrectAllOption('');
                setSelectCorrectOption('');
            } else if (title === 'Selecione um livro' && valueSelected !== 'Selecione um livro') {
                setSelectBook(valueSelected);
            } else if (title === 'Selecione um livro' && valueSelected === 'Selecione um livro') {
                setSelectBook(null);
                setPageBook(null);
                setNumberQuestion(null);
            } else if (title === 'Selecione uma referência' && valueSelected !== 'Selecione uma referência') {
                if (valueSelected !== selectReference) {
                    setNumberQuestion(null);
                    setYear('');
                    setSelectExamColor(null);
                }
                setSelectReference(valueSelected);
            } else if (title === 'Selecione uma referência' && valueSelected === 'Selecione uma referência') {
                setSelectExamColor(null);
                setYear('');
                setNumberQuestion(null);
                setSelectReference(null);
            } else if (title === 'Selecione uma cor' && valueSelected === 'Selecione uma cor') {
                setSelectExamColor(null);
            } else if (title === 'Selecione uma cor' && valueSelected !== 'Selecione uma cor') {
                setSelectExamColor(valueSelected);
            } else if (title === 'Tipo da questão' && valueSelected !== 'Tipo da questão') {
                setTypeQuestion(valueSelected);
            } else if (title === 'Tipo das questões' && valueSelected !== 'Tipo das questões') {
                setTypeQuestion(valueSelected);
            } else if (title === 'Certa ou errada' && valueSelected !== 'Certa ou errada') {
                setSelectCorrectRightOrWrong(valueSelected);
            }
        }

        return (
            <ContainerSelect onChange={ChangeSelect} value={value}>
                {(title !== 'Tipo da questão' && title !== 'Tipo das questões') && title !== 'Certa ou errada' ? (
                    <ContainerOption>{title}</ContainerOption>
                ) : null}
                {RenderOptionsQuestion()}
            </ContainerSelect>
        );
    }

    function RenderSelectOrInput(text: string, subtext: string, value: any, setValue: any) {
        function SelectedArchive(event: any) {
            if (text === 'ENUNCIADO' || text === 'RESOLUÇÃO') {
                const reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                // coletando nome do arquivo
                console.log(event.target.files[0].name);
                if (text === 'ENUNCIADO') {
                    setStatementUploadName(event.target.files[0].name);
                } else {
                    setAnswerUploadName(event.target.files[0].name);
                }
                reader.addEventListener('load', () => {
                    setValue(reader.result);
                });
            } else {
                const newDataFiles = {
                    eventFile: event.target.files[0],
                    eventName: event.target.files[0].name,
                    file: URL.createObjectURL(event.target.files[0]),
                };
                setValue(newDataFiles);
            }
        }

        if (text === 'QUANTAS OPÇÕES' || text === 'RESPOSTA CORRETA' || text === 'SELECIONE UM LIVRO' || text === 'SELECIONE UMA REFERÊNCIA' || text === 'SELECIONE UMA COR' || text === 'TIPO DA QUESTÃO' || text === 'CERTA OU ERRADA' || text === 'TIPO DAS QUESTÕES') {
            let title = '';
            if (text === 'QUANTAS OPÇÕES') {
                title = 'Selecione uma opção';
            }
            if (text === 'RESPOSTA CORRETA') {
                title = 'Selecione uma alternativa';
            }
            if (text === 'SELECIONE UM LIVRO') {
                title = 'Selecione um livro';
            }
            if (text === 'SELECIONE UMA REFERÊNCIA') {
                title = 'Selecione uma referência';
            }
            if (text === 'SELECIONE UMA COR') {
                title = 'Selecione uma cor';
            }
            if (text === 'TIPO DA QUESTÃO') {
                title = 'Tipo da questão';
            }
            if (text === 'TIPO DAS QUESTÕES') {
                title = 'Tipo das questões';
            }
            if (text === 'CERTA OU ERRADA') {
                title = 'Certa ou errada';
            }
            return Select(title, value);
        }
        if (text === 'ENUNCIADO' || text === 'RESOLUÇÃO' || text === 'VÍDEO') {
            const RenderEventName = () => {
                if (userEdit?.id && !value?.eventName) {
                    return text === 'ENUNCIADO' ? 'Upload: ' + statementUploadName : 'Upload: ' + answerUploadName;
                }
                if (value?.eventName === 'Importar .DOCX' || value?.eventName === 'Importar Vídeo') {
                    return value.eventName;
                }
                return `${String(value.eventName)?.substring(0, 25)}...`;
            };
            return (
                <ContainerTest>
                    <Button style={adjustButtonArchive}>
                        <InputNullSelectedArchive
                            accept={text === 'ENUNCIADO' || text === 'RESOLUÇÃO' ? '.docx' : 'video/mp4,video/x-m4v,video/*'}
                            onChange={SelectedArchive}
                            type="file"
                        />
                        <ContainerArchive>
                            <TextArchive>{RenderEventName()}</TextArchive>
                        </ContainerArchive>
                    </Button>
                </ContainerTest>
            );
        }

        if (text === 'CATEGORIAS') {
            return (
                <ContainerMainCategories>
                    {indexCategories !== 1 ? (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <IconArrowCategoriesGoBack
                                src={arrowTip}
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                }}
                            />
                            <TextFinish
                                onClick={() => {
                                    setIndexCategories(1);
                                    setAmountSelected(amountSelectedFalse);
                                    setSubjectSelected(subjectSelectedFalse);
                                    setAmountSelectedFalse([]);
                                    setSubjectSelectedFalse([]);
                                }}
                            >
                                Concluído
                            </TextFinish>
                        </div>
                    ) : null}
                    {value.map((valueCategories: any) => (
                        <>
                            {indexCategories === 1 ? (
                                <Fade in={indexCategories === 1}>
                                    <ContainerCategoriesSelected
                                        onClick={async () => {
                                            const getAllFilters = await filtersRequest.GetSelectedFilters(valueCategories.id, localStorage.getItem('@Engequest:token') || '');
                                            setAllFilters(getAllFilters.result);
                                            setIndexCategories(2);
                                            setIdCategories(valueCategories.id);
                                            setAmountSelectedFalse(amountSelected);
                                            setSubjectSelectedFalse(subjectSelected);
                                        }}
                                    >
                                        <TxtCategoriesSelected>{valueCategories.name}</TxtCategoriesSelected>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ContainerRadius backgroundColor={RenderNumberSelected(valueCategories.id)}>
                                                <text style={{ color: 'white', fontSize: 12 }}>{RenderNumberSelected(valueCategories.id)}</text>
                                            </ContainerRadius>
                                            <IconArrowCategories src={arrowTip} />
                                        </div>
                                    </ContainerCategoriesSelected>
                                </Fade>
                            ) : null}
                            {indexCategories !== 1 && valueCategories.id === idCategories && allFilters.length ? (
                                <>
                                    {allFilters.map((valueFilters: any) => (
                                        <>
                                            <Fade in={indexCategories !== 1}>
                                                <ContainerCategoriesSelected
                                                    onClick={() => {
                                                        if (valueFilters.subjects.length) {
                                                            // setIndexCategories(3);
                                                            setSubjectSelectedFalse([...subjectSelectedFalse || [], ...valueFilters.subjects]);
                                                            handleClick(valueFilters, valueFilters.subjects);
                                                        } else {
                                                            handleClick(valueFilters);
                                                        }
                                                    }}
                                                >
                                                    <div style={{ display: 'flex' }}>
                                                        {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length ? (
                                                            <IconChecked src={checkCircleBlueSea} />
                                                        ) : (
                                                            <ContainerCicleChecked />
                                                        )}
                                                        <TxtCategoriesSelected>{valueFilters.name}</TxtCategoriesSelected>
                                                    </div>
                                                </ContainerCategoriesSelected>
                                            </Fade>
                                            {amountSelectedFalse?.filter((item) => item.id === valueFilters.id).length && valueFilters.subjects.length ? (
                                                <>
                                                    {valueFilters.subjects.map((valueSubjects: any) => (
                                                        <Fade in={indexCategories !== 1}>
                                                            <ContainerCategoriesSelected
                                                                onClick={() => {
                                                                    handleClickSubject(valueSubjects, valueFilters);
                                                                }}
                                                                style={{ marginLeft: 30 }}
                                                            >
                                                                <div style={{ display: 'flex' }}>
                                                                    {subjectSelectedFalse.filter((item) => item.id === valueSubjects.id).length ? (
                                                                        <IconChecked src={checkCircleBlueSea} />
                                                                    ) : (
                                                                        <ContainerCicleChecked />
                                                                    )}
                                                                    <TxtCategoriesSelected>{valueSubjects.name}</TxtCategoriesSelected>
                                                                </div>
                                                            </ContainerCategoriesSelected>
                                                        </Fade>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    ))}
                                </>
                            ) : null}
                        </>
                    ))}
                </ContainerMainCategories>
            );
        }

        const onChangeText = (txt: React.ChangeEvent<HTMLInputElement>) => {
            const onlyNumber = txt.target.value.replace(/[^0-9]/g, '');
            if (text === 'PÁGINA DO LIVRO') {
                if (txt.target.value.length <= 8) {
                    setValue(onlyNumber);
                }
            } else if (text === 'NÚMERO DA QUESTÃO') {
                if (txt.target.value.length <= 6) {
                    setValue(onlyNumber);
                }
            } else if (text === 'ANO') {
                if (txt.target.value.length <= 4) {
                    setValue(onlyNumber);
                }
            } else {
                setValue(txt.target.value);
            }
        };

        return (
            <InputText
                placeholder={subtext}
                value={value}
                type="text"
                onChange={onChangeText}
            />
        );
    }

    function DivInput(text: string, subtext: string, value: any, setValue?: any) {
        const onDownload = () => {
            if (typeof archiveStatement === 'string' && text === 'ENUNCIADO') {
                // const formatArchiveStatement = archiveStatement?.includes('data:') ? archiveStatement?.split(',')[1] : archiveStatement;
                // setCurrentStatement(formatArchiveStatement);
                // Download Funcionando, porém necessita String Base64 do Enunciado/Resposta
                functions.downloadPDF(currentStatement, 'statement');
            }
            if (typeof archiveAnswer === 'string' && text === 'RESOLUÇÃO') {
                // const formatArchiveAnswer = archiveAnswer?.includes('data:') ? archiveAnswer?.split(',')[1] : archiveAnswer;
                // setCurrentAnswer(formatArchiveAnswer);
                functions.downloadPDF(currentAnswer, 'answer');
            }
        };
        return (
            <ContainerHome>
                <DivInputText>
                    <TextInput>{text}</TextInput>
                </DivInputText>
                {RenderSelectOrInput(text, subtext, value, setValue)}
                {text === 'ENUNCIADO' || text === 'RESOLUÇÃO' ? (
                    /* <IconDownload onClick={onDownload} src={iconDownloadBlue} style={{ cursor: 'pointer' }} /> */ null
                ) : null}
            </ContainerHome>
        );
    }

    async function handleLocalClose() {
        clearInterval(intervalClear);
        handleClose(true);
        setMessageBulk('');
        setMessageBulkItems([]);
        setProgressBulk(0);
        setProgressUpload(0);
        setMessageDropzone('Clique para selecionar arquivo [questions.zip]');
        setFileDropzone(null);
        // if (progressBulk !== 100) {
        //     await BulkUpload(100);
        // }
    }

    async function CreateQuestion() {
        if (!userEdit?.id) {
            try {
                // await BulkUpload(0);
                // Upload para DigitalOcean
                const configsFinal = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': GetToken,
                    },
                    onUploadProgress: (event: any) => {
                        const percentCompleted = Math.round((event.loaded * 100) / event.total);
                        setProgressUpload(percentCompleted);
                        setMessageBulk('Enviando o arquivo para nossos servidores.');
                    },
                };
                const bodyUpload = new FormData();
                bodyUpload.append('file', fileDropzone);
                const responseUpload = await axios.post(`${baseURL}/uploads`, bodyUpload, configsFinal);
                const file = responseUpload.data.result;

                // Progress
                const bodyBulk = {
                    fileUrl: file,
                    idManager: Number(user.id),
                };
                // Criando progress no backend
                const responseBulk = await axios.post(`${baseURL}/questions/bulkUpload`, bodyBulk, configs);
                if (responseBulk.status !== 201) {
                    clearInterval(intervalClear);
                    setMessageBulk(responseBulk.data.message);
                    setMessageBulkItems(responseBulk.data.error_items);
                    setProgressId(responseBulk.data.result);
                    setProgressBulk(0);
                    setProgressUpload(0);
                    // Guardar ID do progress
                } else {
                    // Passa id do progress por parâmetro
                    const idProgress = responseBulk.data.result;
                    getProgressBulk(idProgress);
                }
            } catch (error: any) {
                setMessageBulk('Ocorreu um erro ao fazer o upload. Garanta que todos os dados estão corretos e tente novamente.');
                setProgressBulk(0);
                setProgressUpload(0);
                clearInterval(intervalClear);
                await BulkUpload(100);
            }
        } else {
            setIsLoading(true);
            const ValidationUploadArchiveMovie = async () => {
                if (typeof archiveMovie !== 'string' && archiveMovie.file) {
                    const GetUpload = await uploads.UploadFile(archiveMovie.eventFile);
                    return GetUpload.result;
                }
                if (typeof archiveMovie !== 'string' && !archiveMovie.file) {
                    return '';
                }
                return archiveMovie;
            };
            const uploadArchiveMovie = await ValidationUploadArchiveMovie();
            const formatFilters = amountSelected.map((v: any) => v.id);
            const formatSubjects = subjectSelected.map((v: any) => v.id);
            const formatOptions = typeof selectCorrectAllOption === 'string' ? selectCorrectAllOption.split(',') : selectCorrectAllOption;
            const EditQuestion = await questions.EditNewQuestion(
                year,
                selectExamColor,
                selectReference,
                formatFilters,
                formatSubjects,
                selectBook || 0,
                pageBook || 0,
                numberQuestion || 0,
                user?.name || '',
                archiveStatement?.includes('data:') ? archiveStatement?.split(',')[1] : archiveStatement,
                archiveAnswer?.includes('data:') ? archiveAnswer?.split(',')[1] : archiveAnswer,
                uploadArchiveMovie,
                formatOptions,
                selectCorrectOption,
                typeQuestion,
                selectCorrectRightOrWrong,
                userEdit?.id,
                GetToken,
            );
            if (EditQuestion?.status === 200) {
                // Corrigir futuramente -> não usar alert()
                alert('Upload Ok!');
                setIsLoading(false);
                handleLocalClose();
            } else {
                // eslint-disable-next-line no-alert
                alert(EditQuestion.message);
            }
        }
    }

    const handleOnSearchFilters = (txt: string) => {
        setSearchFilters(txt);
    };

    const handleOnSelectFilters = (item: any) => {
        setFiltersSelected(item);
    };

    const handleOnSearchSubjects = (txt: string) => {
        setSearchSubjects(txt);
    };

    const handleOnSelectSubjects = (item: any) => {
        setSubjectsSelected(item);
    };

    const handleOnSearchBooks = (txt: string) => {
        setSearchBooks(txt);
    };

    const handleOnSelectBooks = (item: any) => {
        setBooksSelected(item);
    };

    // main
    return (
        <Modal
            open={open}
            onClose={handleLocalClose}
            closeAfterTransition
        >
            <ModalContainer>
                <Container style={{ width: userEdit ? '100%' : '60%' }}>
                    <Header>
                        {userEdit ? (
                            <div>
                                <MainHeader>
                                    <ContainerHeader>
                                        <ContainerNewCollaborator style={{ marginLeft: '-7px' }}>
                                            <NotebookText color="#475C98" />
                                            <TextNewCollaborator>
                                                Questão {numberQuestion}
                                            </TextNewCollaborator>
                                        </ContainerNewCollaborator>
                                        <DivLine />
                                    </ContainerHeader>
                                    <div style={{ maxHeight: '40px', maxWidth: '10px' }}>
                                        <ButtonClose style={{ padding: '0px' }} onClick={handleLocalClose}>X</ButtonClose>
                                    </div>
                                </MainHeader>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    padding: '15px',
                                    marginLeft: '15px',
                                    gap: '12px',
                                }}
                                >
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderColor: '#74F4D6',
                                        borderWidth: '3px',
                                        borderStyle: 'solid',
                                        borderRadius: '20px',
                                        paddingRight: '25px',
                                        minWidth: '600px',
                                    }}
                                    >
                                        <ContainerHeader>
                                            <ContainerNewCollaborator style={{ marginLeft: '-7px' }}>
                                                <NotebookText color="#475C98" />
                                                <TextNewCollaborator>Enunciado</TextNewCollaborator>
                                            </ContainerNewCollaborator>
                                            <DivLine />
                                        </ContainerHeader>
                                        <p
                                            id="statementElement"
                                            style={{
                                                color: 'black',
                                                minWidth: '100%',
                                                margin: '10px',
                                                paddingLeft: '20px',
                                            }}
                                            dangerouslySetInnerHTML={{ __html: currentStatement }}
                                        />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderColor: '#74F4D6',
                                        borderWidth: '3px',
                                        borderStyle: 'solid',
                                        borderRadius: '20px',
                                        minWidth: '500px',
                                    }}
                                    >
                                        <ContainerHeader>
                                            <ContainerNewCollaborator style={{ marginLeft: '-7px' }}>
                                                <NotebookText color="#475C98" />
                                                <TextNewCollaborator>Resolução</TextNewCollaborator>
                                            </ContainerNewCollaborator>
                                            <DivLine />
                                        </ContainerHeader>
                                        <p style={{ color: 'black', maxWidth: '600px', margin: '10px' }} dangerouslySetInnerHTML={{ __html: currentAnswer }} />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <ContainerHeader>
                            <ContainerNewCollaborator>
                                <IconBlue src={iconAddQuestions} />
                                {userEdit ? <TextNewCollaborator>Atualizar Questão</TextNewCollaborator> : <TextNewCollaborator>Upload de Questões</TextNewCollaborator>}
                            </ContainerNewCollaborator>
                            <DivLine />
                        </ContainerHeader>
                    </Header>
                    {/* !userEdit?.id ? (
                        <ContainerAdjustSelects>
                            <text style={{ color: 'black', marginRight: 10 }}>Filtros:</text>
                            <div style={{ width: 400 }}>
                                <ReactSearchAutocomplete
                                    items={allGeneralFilters}
                                    onSearch={handleOnSearchFilters}
                                    onSelect={handleOnSelectFilters}
                                    onClear={() => setFiltersSelected({})}
                                    showNoResultsText="Nenhum resultado encontrado"
                                    formatResult={(item: any) => (
                                        <ContainerOption>{`${item?.id} - ${item?.name}`}</ContainerOption>
                                    )}
                                />
                            </div>
                            {filtersSelected?.id ? (
                                <>
                                    <text style={{ color: 'black', marginRight: 10, marginLeft: 10 }}>Assuntos:</text>
                                    <div style={{ width: 400 }}>
                                        <ReactSearchAutocomplete
                                            items={allGeneralSubjects}
                                            onSearch={handleOnSearchSubjects}
                                            onSelect={handleOnSelectSubjects}
                                            showNoResultsText="Nenhum resultado encontrado"
                                            formatResult={(item: any) => (
                                                <ContainerOption>{`${item?.id} - ${item?.name}`}</ContainerOption>
                                            )}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <text style={{ color: 'black', marginRight: 10, marginLeft: 10 }}>Livros:</text>
                            <div style={{ width: 400 }}>
                                <ReactSearchAutocomplete
                                    items={allGeneralBooks}
                                    onSearch={handleOnSearchBooks}
                                    onSelect={handleOnSelectBooks}
                                    showNoResultsText="Nenhum resultado encontrado"
                                    formatResult={(item: any) => (
                                        <ContainerOption>{`${item?.id} - ${item?.name}`}</ContainerOption>
                                    )}
                                />
                            </div>
                        </ContainerAdjustSelects>
                    ) : null */}
                    {!userEdit?.id ? (
                        <>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '20px',
                            }}
                            >
                                <ContainerDropzone style={{ backgroundColor: fileDropzone ? 'rgba(255, 147, 38, 0.7)' : '#b9b9b9' }} {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {progressUpload > 0 ? (
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        >
                                            <ContainerProgressBar>
                                                <CircularProgressbar
                                                    value={(progressUpload + progressBulk) / 2}
                                                    text={`${((progressUpload + progressBulk) / 2).toFixed(0)}%`}
                                                />
                                            </ContainerProgressBar>
                                            <TextProgressBar><p dangerouslySetInnerHTML={{ __html: messageBulk }} /></TextProgressBar>
                                            {messageBulkItems.length > 0 ? (messageBulkItems.map((item) => <TextProgressBar>{item}</TextProgressBar>)) : null}
                                        </div>
                                    ) : (
                                        <TextDropzone>
                                            {fileDropzone ? 'Arquivo Selecionado: [' : null}
                                            {`${messageBulk || fileDropzone?.path || messageDropzone}`}
                                            {fileDropzone ? ']' : null}
                                        </TextDropzone>
                                    )}
                                </ContainerDropzone>
                            </div>
                            {/* <ContainerOptionsUpload>
                                {DivInput('TIPO DAS QUESTÕES', 'Tipo das questões', typeQuestion, setTypeQuestion)}
                                {DivInput('CATEGORIAS', 'Categorias', allCategories)}
                            </ContainerOptionsUpload> */}
                        </>
                    ) : (
                        isLoading ? <Spinner /> : (
                            <>
                                <Body>
                                    {/* {DivInput('TÍTULO', 'Título', titleQuestion, setTitleQuestion)} */}
                                    {/* {DivInput('AUTOR', 'Autor', nameAuthor, setNameAuthor)} */}
                                    {DivInput('ENUNCIADO', 'Enunciado', archiveStatement, setArchiveStatement)}
                                    {DivInput('RESOLUÇÃO', 'Resolução', archiveAnswer, setArchiveAnswer)}
                                    {DivInput('VÍDEO', 'Vídeo', archiveMovie, setArchiveMovie)}
                                    {DivInput('SELECIONE UMA REFERÊNCIA', 'Nome', selectReference, setSelectReference)}
                                    {selectReference === 'book' ? (
                                        <>
                                            {DivInput('SELECIONE UM LIVRO', 'Nome', selectBook, setSelectBook)}
                                            {selectBook ? (
                                                <>
                                                    {DivInput('PÁGINA DO LIVRO', 'Página do livro', pageBook, setPageBook)}
                                                    {DivInput('NÚMERO DA QUESTÃO', 'Número da questão', numberQuestion, setNumberQuestion)}
                                                </>
                                            ) : null}
                                        </>
                                    ) : null}
                                    {selectReference === 'contest' ? (
                                        <>
                                            {DivInput('SELECIONE UMA COR', 'Nome', selectExamColor, setSelectExamColor)}
                                            {DivInput('ANO', 'Ano', year, setYear)}
                                            {DivInput('NÚMERO DA QUESTÃO', 'Número da questão', numberQuestion, setNumberQuestion)}
                                        </>
                                    ) : null}
                                </Body>
                                <ContainerManage>
                                    {DivInput('TIPO DA QUESTÃO', 'Tipo da questão', typeQuestion, setTypeQuestion)}
                                    {typeQuestion === 'multiple_choices' ? (
                                        <>
                                            {DivInput('QUANTAS OPÇÕES', 'Nome', selectAmountOption, setSelectAmountOption)}
                                            {DivInput('RESPOSTA CORRETA', 'Nome', selectCorrectOption, setSelectCorrectOption)}
                                        </>
                                    ) : null}
                                    {typeQuestion === 'right_or_wrong' ? DivInput('CERTA OU ERRADA', 'Nome', selectCorrectRightOrWrong, setSelectCorrectRightOrWrong) : null}
                                    {DivInput('CATEGORIAS', 'Categorias', allCategories)}
                                </ContainerManage>
                            </>
                        )
                    )}
                    {userEdit?.id ? (
                        <Footer>
                            <ButtonExit onClick={handleLocalClose}>Cancelar</ButtonExit>
                            <ButtonSave onClick={CreateQuestion}>Editar Questão</ButtonSave>
                        </Footer>
                    ) : (
                        <Footer>
                            <ButtonExit onClick={handleLocalClose}>{`${progressBulk === 100 ? 'Finalizar' : 'Cancelar'}`}</ButtonExit>
                            {progressUpload > 0 || progressBulk === 100 || !fileDropzone ? null : (
                                <ButtonSave onClick={CreateQuestion}>Upload em massa de questões</ButtonSave>
                            )}
                        </Footer>
                    )}
                </Container>
            </ModalContainer>
        </Modal>
    );
};

export default modalManagePage;
